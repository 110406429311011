import React from 'react'
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"

export default ({ data }) => {
  if (!data) {
    return null
  }
  return (
    <div className="coach-list">
      <h2 className="section-headline centered-container">{data.titulo}</h2>
      <ul className="coach-items reset-list row">
        {data.coaches.map((coach, index) => {
          return (
              <li className="columns large-4 medium-6 small-12" key={index}>
                <img src={coach.foto.file.url}/>
                <div className="coach-details">
                  <p className="name">{coach.nombre}</p>
                  <p className="position">{coach.cargo}</p>
                </div>
              </li>
          )
        })}
      </ul>
    </div>
  )
}
