import React from 'react'
import { ModalConsumer } from '../contexts/modal'

export default ({ article }) => (
  <div className="claim-free-class">
    <div className="row">
      <div className="columns small-12">
        <p className="claim">¿Quieres probar cualquiera de nuestras actividades para tener un primer acercamiento totalmente gratis?</p>
        <ModalConsumer>
          {({ toggleModal }) => (
              <p className="action"><button className="free-class btn-primary" onClick={() => toggleModal()}>Prueba Gratis</button></p>
          )}
        </ModalConsumer>
      </div>
    </div>
  </div>
)
