import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Hero from "../components/hero"
import CoachList from "../components/coach-list"
import Gallery from "../components/gallery"
import ClaimFreeClass from '../components/claim-free-class'

class AreaPageTemplate extends React.Component {
  render() {
    const page = get(this.props, 'data.contentfulPaginaDeArea')
    const metatags = get(this.props, 'data.contentfulPaginaDeArea.metatags')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const body_options = {
      renderNode: {
        "embedded-asset-block": (node) => {
          const alt = node.data.target.fields.title['en-US']
          const url = node.data.target.fields.file['en-US'].url
          return <img src={url} alt={alt} />
        }
      }
    }

    return (
      <Layout>
        <Helmet>
          <title>{metatags ? metatags.meta_title || page.titulo : page.titulo } | {siteTitle}</title>
          {metatags && metatags.meta_description &&
            <meta name="description" content={metatags.meta_description.meta_description} />
          }
        </Helmet>
        <div className="area-page wrapper">
          <Hero data={page.hero} />

          {page.texto &&
            <div className="area-text row">
              <div className="columns small-12 medium-10 medium-offset-1">
                { documentToReactComponents(page.texto.json, body_options) }
              </div>
            </div>
          }

          {page.pdf_horarios_tarifas &&
            <div className="area-pdf">
              <div className="row">
                <div className="columns small-12">
                  <div className="area-pdf-content">
                    <p>{page.pdf_horarios_tarifas.description}</p>
                    <a href={page.pdf_horarios_tarifas.file.url} className="btn-primary" target="_blank">
                      Descarga
                    </a>
                  </div>
                </div>
              </div>
            </div>
          }

          <CoachList data={page.listado_coaches} />

          <Gallery data={page.galeria} />

          <ClaimFreeClass />

        </div>
      </Layout>
    )
  }
}

export default AreaPageTemplate

export const pageQuery = graphql`
  query AreaPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPaginaDeArea(slug: { eq: $slug }) {
      titulo
      texto {
        json
      }
      pdf_horarios_tarifas {
        file {
          url
        }
        description
      }
      listado_coaches {
        titulo
        coaches {
          cargo
          nombre
          foto {
            file {
              url
            }
          }
        }
      }
      hero {
        imagen {
          file {
            url
          }
        }
        titulo
        subtitulo
      }
      galeria {
        titulo
        imagenes {
          file {
            url
          }
          fixed(resizingBehavior: THUMB, width: 450, height: 300, cropFocus: CENTER) {
            src
          }
        }
      }
      metatags {
        meta_title
        meta_description {
          meta_description
        }
      }
    }
  }
`
